import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 公共路由
export const constantRoutes = [
  {
    path: '/',
    component: (resolve) => require(['@/components/index'], resolve)
  },
  {
    path: '/work_count',
    component: (resolve) => require(['@/views/work_count'], resolve)
  }

]
export default new Router({
  mode: 'history', // 去掉url中的#
  routes: constantRoutes
})
