import Vue from 'vue'
import App from './App.vue'
import router  from './router'
import store from './store'

import ElementUI from 'element-ui' //element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css'//element-ui的css
Vue.use(ElementUI, {
  size: 'small'
})

import './components/plugins/axios'
import './components/plugins/element.js'
import func from './components/plugins/preload.js'
import nodeWrap from './components/components/nodeWrap'
Vue.use(nodeWrap)
Vue.component('nodeWrap', nodeWrap); //初始化组件
import addNode from './components/components/addNode'
Vue.use(addNode)
Vue.component('addNode', addNode); //初始化组件
Vue.prototype.$func = func;

import Pagination from "@/components/Pagination";
//分页
Vue.component('Pagination', Pagination)

Vue.config.productionTip = false
new Vue({
  el: '#app',
  router,
  store,
  components: {
        nodeWrap,
        addNode,
  },
  render: h => h(App)
})
