import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
   state: {
        promoterDrawer: false,
        flowPermission: [],
        approverDrawer: false,
        approverConfig:{},
        copyerDrawer: false,
        copyerConfig:{},
        conditionDrawer: false,
        conditionsConfig:{
            conditionNodes: [],
        },
        token:''
    },
    mutations: {
        updatePromoter(state,promoterDrawer){
            state.promoterDrawer = promoterDrawer
        },
        updateFlowPermission(state,flowPermission){
            state.flowPermission = flowPermission
        },
        updateApprover(state,approverDrawer){
            state.approverDrawer = approverDrawer
        },
        updateApproverConfig(state,approverConfig){
            state.approverConfig = approverConfig
        },
        updateCopyer(state,copyerDrawer){
            state.copyerDrawer = copyerDrawer
        },
        updateCopyerConfig(state,copyerConfig){
            state.copyerConfig = copyerConfig
        },
        updateCondition(state,conditionDrawer){
            state.conditionDrawer = conditionDrawer
        },
        updateConditionsConfig(state,conditionsConfig){
            state.conditionsConfig = conditionsConfig
        },
    },
    actions: {},
    getters
})

export default store
