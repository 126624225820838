<template>
  <div id="app">
    <router-view />
    <!-- <index/> -->
  </div>
</template>

<script>
// import index from './components/index.vue'

export default {
  name: 'App'
}
</script>

<style>
</style>
